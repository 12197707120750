export class Authorization {
  /**
   *
   * @param {string} role
   */
  constructor(role) {
    switch (role) {
    case 'ADMIN':
      this.rules = ADMIN_RULES;
      break;
    case 'MANAGER':
      this.rules = MANAGER_RULES;
      break;
    case 'THEATER_MANAGER':
      this.rules = THEATER_MANAGER_RULES;
      break;
    default:
      this.rules = new Map();
    }
  }


  can(action, ...params) {
    if (this.rules.has('*')) {
      return this.rules.get('*');
    }
    const rule = this.rules.get(action);
    return !!rule;
  }
}

function build_rules(ruleset) {
  const rules = new Map();
  for (const rule in ruleset) {
    rules.set(rule, ruleset[rule]);
  }
  return rules;
}

export const MANAGER_RULES = build_rules({
  'actors.list': true,
  'actors.view_resume': true,
  'actors.view_skills': true,
  'actors.view_tags': true,
  'actors.view_shows': true,
  'actors.view_additional_info': true,
  'actors.view_dates': true,
  'actors.edit': true,
  'actors.delete': true,
  'actor_headshots.list': true,
  'actor_headshots.create': true,
  'actor_headshots.edit': true,
  'actor_notes.list': true,
  'actor_notes.create': true,
  'actor_notes.edit': true,
  'actor_notes.delete': true,
  'actor_videos.list': true,
  'actor_videos.create': true,
  'actor_videos.edit': true,
  'note_topics.list': true,
  'note_topics.create': true,
  'note_topics.edit': true,
  'note_topics.delete': true,
  'prefab_filters.list': true,
  'prefab_filters.create': true,
  'prefab_filters.edit': true,
  'prefab_filters.delete': true,
  'seasons.list': true,
  'seasons.create': true,
  'seasons.edit': true,
  'seasons.delete': true,
  'shows.create': true,
  'shows.edit': true,
  'shows.delete': true,
  'show_roles.create': true,
  'show_roles.edit': true,
  'show_roles.delete': true,
  'show_roles.view_filters': true,
  'show_roles.view_contract': true,
  'show_role_prospects.list': true,
  'show_role_prospects.create': true,
  'show_role_prospects.edit': true,
  'show_role_prospects.delete': true,
  'show_role_prospect_notes.list': true,
  'show_role_prospect_notes.create': true,
  'show_role_prospect_notes.edit': true,
  'show_role_prospect_notes.delete': true,
  'skills.list': true,
  'skills.create': true,
  'skills.edit': true,
  'skills.delete': true,
  'tags.list': true,
  'tags.create': true,
  'tags.edit': true,
  'tags.delete': true,
});

export const ADMIN_RULES = build_rules({
  '*': true,
});

export const THEATER_MANAGER_RULES = build_rules({
  'seasons.list': true,
  'actor_headshots.list': true,
});
