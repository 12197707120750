import { Queue } from '@/utilities/queue.js';

export class AutoQueue extends Queue {
  constructor() {
    super();
    this._pendingPromise = false;
  }

  /**
   *
   * @param action
   * @return {Promise<unknown>}
   */
  enqueue(action) {
    return new Promise((resolve, reject) => {
      super.enqueue({ action, resolve, reject });
      this.dequeue();
    });
  }

  async dequeue() {
    if (this._pendingPromise) return false;

    let item = super.dequeue();

    if (!item) return false;

    try {
      this._pendingPromise = true;

      let payload = await item.action(this);

      this._pendingPromise = false;
      item.resolve(payload);
    } catch (e) {
      this._pendingPromise = false;
      item.reject(e);
    } finally {
      // next item!
      this.dequeue();
    }

    return true;
  }
}
