import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphQL
const sessionQuery = graph.query(`($isManager: Boolean = false) {
  session {
    wizard {
      actor { ...actorFull }
      email
      createdAt
    }
    user {
      ...userFull
    }
  }
}`);

// endregion

export function retrieve() {
  return sessionQuery()
    .catch(wrapGraphQLError)
    .then((data) => data.session);
}
