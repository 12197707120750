import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

/**
 * Compares properties in the original to the current and returns only changed or added properties
 *
 * @param {object} original The original state of the object
 * @param {object} current The new state of the object
 * @param {Object} options A set of options
 * @param {string[]} options.skip A list of keys to skip when building changes
 * @param {string[]} options.only A list of keys to only check when building changes
 * @returns {object} An object with just the changed or added properties
 */
export function buildChanges(original, current, { skip = [], only = [] } = {}) {
  const changes = {};
  for (const [key, value] of Object.entries(current)) {
    if (skip.includes(key) || (only.length > 0 && !only.includes(key))) continue;

    const v1 = original[key] === '' ? null : original[key];
    const v2 = value === '' ? null : value;

    if (!isEqual(v1, v2)) {
      changes[key] = v2;
    }
  }
  return changes;
}

/**
 * Returns a copy of the object with all entries equal to a blank string removed
 *
 * @param {object} input object to clean
 * @param {object} options
 * @param {array} remove keys with any of these values are removed
 * @param {string[]} skip keys to skip regardless of the value
 * @returns {object} The cleaned object with blank entries (==='', ===undefined) removed
 */
export function clearEmpty(input, { remove = ['', undefined], skip = [] } = {}) {
  const cleaned = {};
  for (const [key, value] of Object.entries(input)) {
    if (!remove.includes(value) && !skip.includes(key)) {
      cleaned[key] = value;
    }
  }
  return cleaned;
}

/**
 * Returns a new object deep cloning the specified fields
 *
 * @param {object} original The original object
 * @param {string[]} fields The list of fields to clone
 * @returns {object} The new object
 */
export function partialCloneDeep(original, fields) {
  const obj = {};
  for (const field of fields) {
    obj[field] = cloneDeep(original[field]);
  }
  return obj;
}

export { cloneDeep };

/**
 * Product an intersection set between two iterables of records
 *
 * @param {Iterator<string>} iterA
 * @param {Iterator<string>} iterB
 * @returns {Set<string>}
 */
export function intersection(iterA, iterB) {
  const _intersection = new Set();
  for (const elem of iterB) {
    if (iterA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

/**
 * Product a set of all values in iterA that are not in iterB
 *
 * @param {Iterator<string>} iterA
 * @param {Iterator<string>} iterB
 * @returns {Set<string>}
 */
export function difference(iterA, iterB) {
  const _difference = new Set(iterA);
  for (const elem of iterB) {
    _difference.delete(elem);
  }
  return _difference;
}

/**
 * Adds or updates the entry into the store
 *
 * For an update it "merges" keys (vs replacing the object)
 *
 * @param {object[]} records
 * @param {object} entry
 * @param {Object} options A set of options
 * @param {string[]} options.skip A list of keys to skip when building changes
 * @param {string[]} options.only A list of keys to include when building changes
 */
export function addUpdateRecord(records, entry, { skip = [], only = [] } = {}) {
  if (!entry.id) {
    console.error('No ID!', entry);
  }
  let newEntry;
  if (only.length > 0) {
    newEntry = {};
    for (const key of only) {
      newEntry[key] = entry[key];
    }
  } else {
    newEntry = Object.assign({}, entry);
  }
  for (const key of skip) {
    delete newEntry[key];
  }
  const idx = records.findIndex(m => m.id === entry.id);
  if (idx < 0) {
    records.push(newEntry);
  } else {
    newEntry = Object.assign({}, records[idx], newEntry);
    records[idx] = newEntry;
  }
  return newEntry;
}

/**
 * Removes an entry from the store
 *
 * @param {object[]} records
 * @param {string} entry_id
 */
export function removeRecord(records, entry_id) {
  const idx = records.findIndex(m => m.id === entry_id);
  if (idx >= 0) {
    records.splice(idx, 1);
  }
}

export function buildAddRemoveChanges(original, current, { includeEmpty = false } = {}) {
  const changes = {};
  if (includeEmpty) {
    changes.created = [];
    changes.deleted = [];
  }
  const origIds = new Set(original);
  const newIds = new Set(current);
  const removedIds = difference(origIds, newIds);
  const addedIds = difference(newIds, origIds);
  if (addedIds.size > 0) {
    changes.created = Array.from(addedIds);
  }
  if (removedIds.size > 0) {
    changes.deleted = Array.from(removedIds);
  }

  return changes;
}

export function buildArrayChanges(original, current, updateKey, {
  updateOptions = {},
  addOptions = { skip: ['id'] },
} = {}) {
  const changes = {};

  const origIds = new Set(original.map(e => e.id));
  const newIds = new Set(current.map(e => e.id));
  const updatedIds = intersection(origIds, newIds);
  const removedIds = difference(origIds, newIds);
  const addedIds = difference(newIds, origIds);
  if (addedIds.size > 0) {
    changes.created = current.filter(e => addedIds.has(e.id)).map(e => {
      return clearEmpty(e, addOptions);
    });
  }
  if (removedIds.size > 0) {
    changes.deleted = Array.from(removedIds);
  }
  if (updatedIds.size > 0) {
    const updated =
      current.filter(e => updatedIds.has(e.id)).map(e => {
        const entryChanges = buildChanges(original.find(i => i.id === e.id), e, updateOptions);
        if (Object.keys(entryChanges).length > 0) {
          return ({
            [updateKey]: e.id,
            ...entryChanges,
          });
        }
      }).filter(e => e !== undefined);

    if (updated.length > 0) {
      changes.updated = updated;
    }
  }

  return changes;
}

export async function doLoading(store, proc) {
  try {
    store.loading++;

    return await proc();
  } finally {
    store.loading--;
  }
}

export function isExisting(id) {
  return !(!id || id.startsWith('_new'));
}

export function groupBy(iter, callbackFn) {
  const target = new Map;
  for (const elem of iter) {
    const key = callbackFn(elem);
    if (target.has(key)) {
      target.get(key).push(elem);
    } else {
      target.set(key, [elem]);
    }
  }
  return target;
}

export function toSorted(array, callbackFn) {
  if (Array.prototype.toSorted) {
    return array.toSorted(callbackFn);
  } else {
    return [...array].sort(callbackFn);
  }
}

/**
 *
 * @param {Map<any, any>} map
 * @param {(aKey: any, bKey: any, aValue: any, bValue: any) => Number} keySort
 *  If specify override default key sorting
 * @param {((a: any,b: any) => Number)|true} valueSort If specified then perform sorting of values
 *  (assumes each value is an array)
 */
export function sortMap(map, { keySort = null, valueSort = null } = {}) {
  const sortedKeys = [...map.entries()].sort((a, b) => {
    if (keySort) {
      return keySort(a[0], b[0], a[1], b[1]);
    } else {
      return a[0] > b[0] ? 1 : -1;
    }
  });
  if (valueSort) {
    for (const [_, v] of sortedKeys) {
      v.sort((a, b) => {
        if (valueSort === true) {
          return a > b ? 1 : -1;
        } else {
          return valueSort(a, b);
        }
      });
    }
  }
  return new Map(sortedKeys);
}

export function indexBy(iter, callbackFn) {
  const target = new Map;
  for (const elem of iter) {
    const key = callbackFn(elem);
    target.set(key, elem);
  }
  return target;
}

/**
 * Checks if the URLs are expired and need to be refreshed
 *
 * @param urls
 * @returns {boolean}
 */
export function urlsExpired(urls) {
  if (isEmpty(urls)) return true;

  // add 30 seconds of fuzz to account for clock drift
  const now = DateTime.now().plus({ seconds: 30 });

  for (const entry of urls) {
    if (urlExpired(entry, now)) return true;
  }

  return false;
}

export function urlExpired(urlEntry, now = null) {
  if (isEmpty(urlEntry)) return true;

  // add 30 seconds of fuzz to account for clock drift
  now = now || DateTime.now().plus({ seconds: 30 });

  const expires = DateTime.fromISO(urlEntry.expiration);
  return now > expires;
}

export function urlForStyle(urlEntries, style) {
  return urlEntries?.find(u => u.style === style);
}

export function sleep(timeoutMS) {
  return new Promise(resolve => setTimeout(resolve, timeoutMS));
}
