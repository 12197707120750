import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useActorHeadshotStore } from '@/stores/actor_headshots';
import { useActorVideoStore } from '@/stores/actor_videos';
import { useActorNoteStore } from '@/stores/actor_notes';
import { useSkillStore } from '@/stores/skills';
import { useShowRoleStore } from '@/stores/show_roles';
import { useShowRoleProspectStore } from '@/stores/show_role_prospects';
import { useTagStore } from '@/stores/tags';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord, urlsExpired,
} from '@/utilities/helpers';
import { UploadManager } from '@/utilities/upload_manager';

export const useActorStore = defineStore('actors', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (actorId) => state.records.find((v) => v.id === actorId),
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    fetchName(actorId) {
      return actorId ? this.retrieve(actorId)?.name : undefined;
    },
    async processRecord(actor) {
      await useActorHeadshotStore().processRecords(actor.headshots);
      await useActorVideoStore().processRecords(actor.videos);
      await useActorNoteStore().processRecords(actor.notes);
      await useSkillStore().processRecords(actor.skills);
      await useTagStore().processRecords(actor.tags);
      await useShowRoleStore().processRecords(actor.showRoles);
      await useShowRoleProspectStore().processRecords(actor.showRoleProspects);
      return addUpdateRecord(this.records, actor, {
        skip: ['headshots', 'videos', 'tags', 'skills', 'notes', 'showRoles', 'showRoleProspects'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(actorId) {
      await useActorHeadshotStore().removeRecordsBy({ actorId });
      await useActorVideoStore().removeRecordsBy({ actorId });
      await useActorNoteStore().removeRecordsBy({ actorId });
      removeRecord(this.records, actorId);
    },
    async retrieveAsync(actorId, { force = false } = {}) {
      let record = this.records.find((v) => v.id === actorId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.actors.retrieve({ actorId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async sync() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.actors.list,
          node: 'actors',
          filters: { limit: 100 },
        }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async setPrimaryHeadshot(actorId, headshotId) {
      const original = this.retrieve(actorId);
      return await this.saveChanges(actorId, buildChanges(original,
        { primaryActorHeadshotId: headshotId },
        {
          only: ['primaryActorHeadshotId'],
        }));
    },
    async retrieveResumeUrls(actorId) {
      let record = this.records.find((v) => v.id === actorId);
      await doLoading(this, async () => {
        if (!record) {
          record = await api.actors.retrieve({ actorId });
          record = await this.processRecord(record);
        } else {
          if (urlsExpired(record.resumeUrls)) {
            record = await api.actors.retrieveResumeUrls({ actorId });
            record = await this.processRecord(record);
          }
        }
      });
      return record;
    },
    async deleteResumeUpload({ actorId }) {
      const data = await api.actors.uploadResume({
        actorId, upload: { delete: {} },
      });

      await this.processRecord(data.actor);
    },
    async startResumeUpload({ actorId, contentType, fileName }) {
      const initial = await api.actors.uploadResume({
        actorId, upload: { start: { contentType, fileName } },
      });
      await this.processRecord(initial.actor);
      return new UploadManager(initial.presignedPost, async (completionToken) => {
        const data = await api.actors.uploadResume({ actorId, upload: { finish: { completionToken } } });
        await this.processRecord(data.actor);
      });
    },
    async saveChanges(actorId, updates) {
      const payload = { actorId, updates };

      return await doLoading(this, async () => {
        if (Object.keys(payload.updates).length > 0) {
          return await api.actors.update(payload).then(async (data) => {
            return this.processRecord(data.actor);
          });
        } else {
          return this.retrieve(actorId);
        }
      });
    },
    async save(actor) {
      if (isExisting(actor.id)) {
        // existing
        const original = this.retrieve(actor.id);
        const changes = buildChanges(original, actor, {
          skip: ['id', 'updatedAt', 'skillIds', 'resumeUrls', 'primaryActorHeadshotId'],
        });

        return this.saveChanges(actor.id, changes);
      } else {
        // new actor
        const prepared = {
          actor: clearEmpty(actor),
        };
        return await doLoading(this, async () => {
          return await api.actors.create(prepared).then(async (data) => {
            return this.processRecord(data.actor);
          });
        });
      }
    },
    async delete(actorId) {
      const actor = this.retrieve(actorId);
      if (!actor) {
        return;
      }
      const payload = { actorId };

      return await doLoading(this, async () => {
        return await api.actors.delete(payload).then(async data => {
          await this.removeRecord(data.actorId);
          return data.actorId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActorStore, import.meta.hot));
}
