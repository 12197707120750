import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useShowRoleProspectNoteStore } from '@/stores/show_role_prospect_notes';
import { useShowRoleStore } from '@/stores/show_roles';
import { useActorStore } from '@/stores/actors';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers';

export const useShowRoleProspectStore = defineStore('showRoleProspects', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (showRoleProspectId) => state.records.find((v) => v.id === showRoleProspectId),
    isLoading: (state) => state.loading > 0,
    forShowRoleId: (state) => (showRoleId) => state.records.filter((v) => v.showRoleId === showRoleId),
    forActorId: (state) => (actorId) => state.records.filter((v) => v.actorId === actorId),
    find: (state) => ({
      showRoleId,
      actorId,
    }) => state.records.find(v => v.showRoleId === showRoleId && v.actorId === actorId),
  },
  actions: {
    async processRecord(record) {
      await useShowRoleProspectNoteStore().processRecords(record.notes);
      if (record.showRole) {
        await useShowRoleStore().processRecord(record.showRole);
      }
      if (record.actor) {
        await useActorStore().processRecord(record.actor);
      }
      return addUpdateRecord(this.records, record, {
        skip: ['notes', 'showRole', 'actor'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(showRoleProspectId) {
      await useShowRoleProspectNoteStore().removeRecordsBy({ showRoleProspectId });

      removeRecord(this.records, showRoleProspectId);
    },
    async removeRecordsBy({ showRoleId }) {
      if (showRoleId) {
        const toRemove = this.forShowRoleId(showRoleId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    async retrieveAsync(showRoleProspectId, { force = false } = {}) {
      let record = this.records.find((v) => v.id === showRoleProspectId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.showRoleProspects.retrieve({ showRoleProspectId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async reorder(showRoleProspectId, position, relatedShowRoleProspectId = null) {
      if (isExisting(showRoleProspectId)) {
        const payload = {
          showRoleProspectId,
          position,
        };
        if (relatedShowRoleProspectId) {
          payload.relatedShowRoleProspectId = relatedShowRoleProspectId;
        }
        return await doLoading(this, async () => {
          return await api.showRoleProspects.reorder(payload).then(async (data) => {
            await this.processRecords(data.updatedShowRoleProspects);
            return this.processRecord(data.showRoleProspect);
          });
        });
      } else {
        throw new Error('Show role prospect ID does not exists');
      }
    },
    async saveChanges(showRoleProspectId, updates) {
      const payload = { showRoleProspectId, updates };

      return await doLoading(this, async () => {
        if (Object.keys(payload.updates).length > 0) {
          return await api.showRoleProspects.update(payload).then(async (data) => {
            return this.processRecord(data.showRoleProspect);
          });
        } else {
          return this.retrieve(showRoleProspectId);
        }
      });
    },
    async save(showRoleProspect) {
      if (isExisting(showRoleProspect.id)) {
        // existing
        const original = this.retrieve(showRoleProspect.id);
        const changes = buildChanges(original, showRoleProspect, {
          skip: ['id', 'updatedAt'],
        });

        return this.saveChanges(showRoleProspect.id, changes);
      } else {
        // new showRole
        const prepared = {
          showRoleProspect: clearEmpty(showRoleProspect),
        };
        return await doLoading(this, async () => {
          return await api.showRoleProspects.create(prepared).then(async (data) => {
            return this.processRecord(data.showRoleProspect);
          });
        });
      }
    },
    async delete(showRoleProspectId) {
      const showRoleProspect = this.retrieve(showRoleProspectId);
      if (!showRoleProspect) {
        return;
      }
      const payload = { showRoleProspectId };

      return await doLoading(this, async () => {
        return await api.showRoleProspects.delete(payload).then(async data => {
          await this.removeRecord(data.showRoleProspectId);
          return data.showRoleProspectId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShowRoleProspectStore, import.meta.hot));
}
