import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';

export const useUserStore = defineStore('users', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (userId) => state.records.find((v) => v.id === userId),
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    async processRecord(user) {
      return addUpdateRecord(this.records, user);
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(userId) {
      removeRecord(this.records, userId);
    },
    async retrieveAsync(userId, { force = false } = {}) {
      let record = this.records.find((v) => v.id === userId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.users.retrieve({ userId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async sync() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({ fetch: api.users.list, node: 'users', filters: { limit: 100 } }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async save(user) {
      if (isExisting(user.id)) {
        // existing
        const original = this.retrieve(user.id);
        const payload = {
          userId: user.id,
          updates: buildChanges(original, user, { skip: ['id', 'updatedAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.users.update(payload).then(async (data) => {
              return this.processRecord(data.user);
            });
          } else {
            return original;
          }
        });
      } else {
        // new users
        const prepared = {
          user: clearEmpty(user),
        };
        return await doLoading(this, async () => {
          return await api.users.create(prepared).then(async (data) => {
            return this.processRecord(data.user);
          });
        });
      }
    },
    async delete(userId) {
      const user = this.retrieve(userId);
      if (!user) {
        return;
      }
      const payload = { userId };

      return await doLoading(this, async () => {
        return await api.users.delete(payload).then(async data => {
          await this.removeRecord(data.userId);
          return data.userId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
